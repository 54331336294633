
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddProductModal from "@/components/modals/forms/AddProductModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";



export default defineComponent({
  name: "products-listing",
   data() {
    return {
      produtos: [],
      produto: {}
    }
  },
  mounted() {
	   this.$nextTick(function () {
		 this.getProdutos();
	
	})

  },

  methods:{

  novo(){

    this.produto = {}

  },


  editar(index){

    this.produto = this.produtos[index]


  },  

  excluir(id){

    Swal.fire({
      title: 'Excluir?',
      text: "Tem certeza que deseja excluir este registro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir!'
    }).then((result) => {
      if (result.isConfirmed) {


        axios.delete(process.env.VUE_APP_API_URL + '/produtos/'+id)
       .then(({ data }) => {   
          Swal.fire(
            'Deletado!',
            'Seu registro foi deletado.',
            'success'
          ).then(()=>{
            this.getProdutos();
          })



        })
        
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          console.log(response)
        });


      }

       
      
    })

  },
    
	getProdutos(){

		axios.get(process.env.VUE_APP_API_URL + '/produtos')
       .then(({ data }) => {   
        this.produtos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


    }
  },
  components: {
    Datatable,
    AddProductModal,
  },
  computed: {
    // mix the getters into computed with object spread operator
    
  },
  setup() {
   
    const tableHeader = ref([
     
      {
        name: "Nome",
        key: "nome",
        sortable: true,
      },
      {
        name: "Descrição",
        key: "descricao",
        sortable: true,
      },
      {
        name: "Valor",
        key: "valor",
        sortable: true,
      },
      {
        name: "Opções",
        key: "opcao",
        sortable: false,
        
      }
    ]);

    onMounted( async () => {
      setCurrentPageBreadcrumbs("Lista de Produtos", ["Produtos"]);
      
     
    });  

   
    return {
       tableHeader,
      
      
    };
  },
});
